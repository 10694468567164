import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Kanap.scss";

function Kanap() {
  return (
    <>
      <Header />
      <main className="main__container">
        <span>Kanap</span>
      </main>
      <Footer />
    </>
  );
}

export default Kanap;
