import { useState } from "react";
import "./FormInput.scss";

const FormInput = (props) => {
  const [checked, setChecked] = useState(false);
  const { errormessage, onChange, id, ...input } = props;

  const handleBlur = (e) => {
    setChecked(true);
  };

  if (input.name === "message") {
    return (
      <>
        <textarea
          {...input}
          onChange={onChange}
          onBlur={handleBlur}
          ischecked={checked.toString()}
          cols="30"
          rows="10"
        ></textarea>
        <span>{errormessage}</span>
      </>
    );
  } return (
    <>
      <input
        {...input}
        onChange={onChange}
        onBlur={handleBlur}
        ischecked={checked.toString()}
      />
      <span>{errormessage}</span>
    </>
  );
};

export default FormInput;
