import "./About.scss";

import profilePic from "../../assets/photocv-bw.jpg";
import Header from "../../components/Header/Header";
import CV from "../../assets/cv_amloria_2023.pdf";

function About() {
  return (
    <>
      <Header />
      <main className="main__container">
        <section className="section__about">
          <div className="intro__img-name">
            <img
              src={profilePic}
              alt="Agustin Loria Web Developer"
              className="intro_img"
            />
            <div className="intro__name">
              <h1>Agustín Loria</h1>
              <p>Développeur Web</p>
            </div>
          </div>
          <div className="about__container">
            <div className="about__base">
              <h2>Qui suis-je ?</h2>
              <p>
                Toujours motivé, j'apprends chaque jour à devenir un meilleur
                développeur.
              </p>
              <p>
                Grâce à mon esprit analytique, je sais reconnaître les
                différentes étapes d'un projet web de manière à le décomposer en
                plusieurs tâches ce qui me permet d'atteindre les objetifs avec
                méthodologie.
              </p>
              <p>
                Créatif, je formule des propositions innovantes que je parviens
                à mettre en place grâce à ma rigueur.
              </p>
              <p>
                Je m'adapte rapidement à de nouvelles missions et ma curiosité
                me pousse à toujours développer mes compétences.
              </p>
              <p>
                Je parle couramment français et anglais, j'ai des notions de
                portugais et ma langue maternelle est l'espagnol.
              </p>
            </div>
            <button className="button__learn-more">
              <a href={CV} download>
                Téléchargez mon CV
              </a>
            </button>
            <div className="about__Education">
              <h2>Qu'est-ce que je suis capable de faire ?</h2>
              <p>
                Construire un site web responsive s’adaptant à tout type d’écran
                grâce à l’intégration des éléments des maquettes graphiques
                (développement front-end avec HTML, CSS/SCSS, Vanilla JavaScript
                et React).
              </p>
              <p>
                Créer des APIs et des bases de données sécurisées pour
                développer des sites complets et dynamiques (développement
                back-end avec NodeJS, Express et MongoDB).
              </p>
              <p>
                Optimiser les performances et réaliser la maintenance de sites
                web déjà existants pour permettre leur fonctionnement optimal et
                une bonne visibilité sur les moteurs de recherche (SEO).
              </p>
              <p>
                Actuellement, PHP et WordPress font partie de mon quotidien dans
                un contexte professionnel ainsi que WSL, GitLab, Docker,
                JavaScript et Sass entre autres.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default About;
