import "./Amloria.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../Home/Home";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import NotFound from "../NotFound/NotFound";
import Groupomania from "../../components/Groupomania/Groupomania";
import Piiquante from "../../components/Piiquante/Piiquante";
import Kanap from "../../components/Kanap/Kanap";
import LaPanthere from "../../components/LaPanthere/LaPanthere";

function Amloria() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="projects" element={<Projects />} />
        <Route exact path="projects/groupomania" element={<Groupomania />} />
        <Route exact path="projects/piiquante" element={<Piiquante />} />
        <Route exact path="projects/kanap" element={<Kanap />} />
        <Route exact path="projects/lapanthere" element={<LaPanthere />} />
        <Route exact path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Amloria;
