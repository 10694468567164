import "./Home.scss";
import profilePic from "../../assets/photocv-bw.jpg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import About from "../About/About";
import Icons from "../../components/Icons/Icons";
import ProjectsComponent from "../../components/ProjectsComponent/ProjectsComponent";

function Home() {
  return (
    <>
      <Header />
      <main className="main__container">
        <div className="main__wrapper">
          <section className="section__intro">
            <h1>Bonjour & Bienvenu</h1>
            <h2>
              Je suis Agustin, Développeur fullstack passionné par la création
              d'applications web.
            </h2>
            <article className="article__intro">
              <img
                src={profilePic}
                alt="Agustin Loria Web Developer"
                className="article__intro_img"
              />
              <p>
                Mon objectif est d'aider les gens en créant des applications web
                accessibles, de haute qualité et performantes.
              </p>
              <a
                href="https://calendly.com/agustin-loria"
                target="_blank"
                rel="noreferrer"
                aria-label="Contact"
                className="contact__calendly"
              >
                Prenons contact
              </a>
            </article>
            <Icons />
          </section>

          <ProjectsComponent />

          <About />
        </div>

        <div className="projects__feedback">
          <h4>Envie de discuter ?</h4>
          <div className="arrow__down">
            <i className="fa-solid fa-lg fa-arrow-down"></i>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Home;
