import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Groupomania.scss";

function Groupomania() {
  return (
    <>
      <Header />
      <main className="main__container">
        <span>Groupomania</span>
      </main>
      <Footer />
    </>
  );
}

export default Groupomania;
