import { Link } from "react-router-dom";
import Amloria from "../../pages/Amloria/Amloria";
import "./Header.scss";

function Header() {
  return (
    <header className="header__navbar">
      <div className="header__navbar__logo">
        <Link to="/" element={<Amloria />}>
          AMLORIA
        </Link>
      </div>
    </header>
  );
}

export default Header;
