import "./Footer.scss";
// import logoMalt from "../../assets/logoMalt.png";

import { Link } from "react-router-dom";
import Amloria from "../../pages/Amloria/Amloria";

function Footer() {
  return (
    <footer className="footer__container">
      <ul className="footer__list">
        <li className="footer__link">
          <a target="_blank" rel="noreferrer" href="https://github.com/amloria">
            <i className="fa-brands fa-xl fa-github"></i>
            <span>GitHub</span>
          </a>
        </li>
        <li className="footer__link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/agustin-loria/"
          >
            <i className="fa-brands fa-xl fa-linkedin"></i>
            <span>Linkedin</span>
          </a>
        </li>
        <li className="footer__link">
          <a href="mailto:amloria25@gmail.com">
            <i className="fa-regular fa-xl fa-envelope"></i>
            <span>Email</span>
          </a>
        </li>
        {/* <li className="footer__link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.malt.fr/profile/agustinloria"
            className="link__malt"
          >
            <img
              src={logoMalt}
              alt="Logo de Malt"
              className="link__img__malt"
            />
            <span>Malt</span>
          </a>
        </li> */}
      </ul>
      {/* <div className="footer__select-language">
        <label htmlFor="lang-selection">Select language</label>
        <select name="language" id="language">
          <option value="">Choose an option</option>
          <option value="english">English (EN)</option>
          <option value="french">Français (FR)</option>
          <option value="spanish">Español (ES)</option>
          <option value="french">Português (PT)</option>
        </select>
      </div> */}
      <div className="footer__copyright">
        <small>
          © 2023 | Fait avec <span>❤</span> par{" "}
          <Link to="/" element={<Amloria />}>
            amloria
          </Link>
        </small>
      </div>
    </footer>
  );
}

export default Footer;
