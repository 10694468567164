import "./ProjectsComponent.scss";
// import New from "../New/New";
import logoGroupomania from "../../assets/article-collaboration-teamwork-in-the-workplace-2x.jpg";
import logoPiiquante from "../../assets/imageapi.png";
import logoKanap from "../../assets/ecommercekanap.jpg";
import logoLaPanthere from "../../assets/auditwebseo.png";
import imgBlog from "../../assets/blogs.jpg";
// import LearnMore from "../LearnMore/LearnMore";
// import Groupomania from "../Groupomania/Groupomania";
// import Piiquante from "../Piiquante/Piiquante";
// import Kanap from "../Kanap/Kanap";
// import LaPanthere from "../LaPanthere/LaPanthere";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCards } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/effect-cards";

function ProjectsComponent() {
  return (
    <div className="section__projects swiper">
      <h2>Mes projets</h2>
      <div className="projects__container">
        <Swiper
          modules={[Navigation, Pagination, A11y, EffectCards]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={false}
          effect={"cards"}
          loop={true}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="slide__container">
              <div className="article__project__container">
                {/* <New /> */}
                <img
                  src={imgBlog}
                  alt="Logo Groupomania, intern social network"
                  className="project__img groupomania"
                />
                <div className="article__project__intro">
                  <h3>Next.js Blog</h3>
                  <p>
                    Développement d'un blog avec TypeScript et Next.js pour
                    mettre en pratique la théorie apprise dans la{" "}
                    <a
                      href="https://nextjs.org/learn/foundations/about-nextjs"
                      target="_blank"
                      rel="noreferrer"
                      className="project__ref"
                    >
                      documentation de Next.js.
                    </a>
                  </p>
                  <div className="button__links">
                    <button className="button__learn-more">
                      <a
                        href="https://github.com/amloria/nextjs-blog"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Voir le code
                      </a>
                    </button>
                    <button className="button__live">
                      <a
                        href="https://nextjs-blog-amloria.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Voir en live
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide__container">
            <div className="slide__container">
              <div className="article__project__container">
                {/* <New /> */}
                <img
                  src={logoGroupomania}
                  alt="Logo Groupomania, intern social network"
                  className="project__img groupomania"
                />
                <div className="article__project__intro">
                  <h3>Réseau social d'entreprise pour Groupomania</h3>
                  <p>
                    Création d'une application intranet où j'ai mis en place le
                    backend, le frontend et la base de données en utilisant
                    MongoDB, Express, React et Node.js.
                  </p>
                  <button className="button__learn-more">
                    <a
                      href="https://github.com/amloria/P7_Groupomania"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Voir le code
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide__container">
            <div className="slide__container">
              <div className="article__project__container">
                <img
                  src={logoPiiquante}
                  alt="Logo Piiquante, a secure API for a food review app"
                  className="project__img"
                />

                <div className="article__project__intro">
                  <h3>
                    API sécurisée pour une application de critiques alimentaires
                  </h3>
                  <p>
                    J'ai construit l'API de "Hot Takes" en implémentant des
                    opérations CRUD et un modèle de données logique conformément
                    à la réglementation pour stocker les données en toute
                    sécurité.
                  </p>
                  <button className="button__learn-more">
                    <a
                      href="https://github.com/amloria/P6_Piiquante"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Voir le code
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide__container">
            <div className="slide__container">
              <div className="article__project__container">
                <img
                  src={logoKanap}
                  alt="Logo Kanap, online store development"
                  className="project__img"
                />

                <div className="article__project__intro">
                  <h3>E-commerce / Développement d'une boutique en ligne</h3>
                  <p>
                    Dans ce projet mon rôle était d'unifier le travail effectué
                    par l'équipe en intégrant dynamiquement les éléments d'une
                    API dans les différentes pages web avec JavaScript et de
                    mettre en place un plan de test d'acceptation.
                  </p>
                  <button className="button__learn-more">
                    <a
                      href="https://github.com/amloria/P5_Kanap"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Voir le code
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide__container">
            <div className="slide__container">
              <div className="article__project__container">
                <img
                  src={logoLaPanthere}
                  alt="Logo La Panthère, web design agency based in Lyon"
                  className="project__img"
                />

                <div className="article__project__intro">
                  <h3>
                    Optimisation SEO, accessibilité et amélioration des
                    performances
                  </h3>
                  <p>
                    Analyse et amélioration du SEO de La Panthère, Agence Web
                    basée à Lyon, en optimisant la taille des fichiers, la
                    vitesse de chargement et en assurant l'accessibilité du
                    site.
                  </p>
                  <button className="button__learn-more">
                    <a
                      href="https://github.com/amloria/P4_La_Panthere"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Voir le code
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default ProjectsComponent;
