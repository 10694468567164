import "./Icons.scss";

function Icons() {
  return (
    <div className="intro__icons">
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-html5"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-css3-alt"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-js"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-node"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-react"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-sass"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-brands fa-xl fa-github"></i>
      </div>
      <div className="intro__icon">
        <i className="fa-solid fa-xl fa-database"></i>
      </div>
    </div>
  );
}

export default Icons;
