import "./Contact.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";

function Contact() {
  return (
    <>
      <Header />
      <main className="main__container">
        <section className="section__contact">
          <h1>Feel free to contact me, I will be delighted to discuss with you</h1>
          <ContactForm />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Contact;
