import "./Projects.scss";
import Header from "../../components/Header/Header";
import ProjectsComponent from "../../components/ProjectsComponent/ProjectsComponent";
import Footer from "../../components/Footer/Footer";

function Projects() {
  return (
    <>
      <Header />
      <main className="main__container">
        <ProjectsComponent />
        <div className="projects__feedback">
          <h4>
            Envie de discuter ?
          </h4>
          <div className="arrow__down">
            <i className="fa-solid fa-lg fa-arrow-down"></i>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Projects;
