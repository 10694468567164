import "./ContactForm.scss";
import FormInput from "../FormInput/FormInput";
import { useState } from "react";

function ContactForm() {
  const [success, setSuccess] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const formInputs = [
    {
      type: "text",
      name: "name",
      id: "name",
      placeholder: "Pleased to meet you! What's your name?",
      label: "Name",
      required: true,
      pattern: `^[A-Za-z]{2,16}$`,
      errormessage:
        "The first name must be between 2 and 16 characters and must not contain numbers",
    },
    {
      type: "text",
      name: "lastName",
      id: "lastName",
      placeholder: "And your last name?",
      label: "Last name",
      required: true,
      pattern: `^[A-Za-z]{2,16}$`,
      errormessage:
        "Last name must be between 2 and 16 characters and must not contain numbers",
    },
    {
      type: "email",
      name: "email",
      id: "email",
      placeholder: "example@gmail.com",
      label: "Email address",
      required: true,
      pattern: `[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+$`,
      errormessage: "Invalid email address",
    },
    {
      type: "text",
      name: "messageSubject",
      id: "messageSubject",
      placeholder: "How can I help you?",
      label: "Subject",
      required: true,
      pattern: `^[A-Za-z0-9]{2,140}$`,
      errormessage: "Empty field",
    },
    {
      type: "text",
      name: "message",
      cols: "30",
      rows: "10",
      id: "message",
      placeholder: "Any details you might want to include?",
      label: "Message",
      required: true,
      pattern: `^[A-Za-z0-9]{2,500}$`,
      errormessage: "Empty field",
    },
  ];

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  function onSubmit(e) {
    e.preventDefault();

    setSuccess(true);

    // fetch("http://localhost:3000/api/auth/signup", {
    //   method: "POST",
    //   body: JSON.stringify(formValues),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then(function (apiData) {
    //     if (apiData.ok) {
    //       return apiData.json();
    //     }
    //   })
    //   .then(() => {
    //     setSuccess(true);
    //   })
    //   .catch(function (err) {
    //     console.error(`Retour du serveur : ${err}`);
    //   });
  }

  return (
    <form className="form__container" onSubmit={onSubmit}>
      {formInputs.map((input) => (
        <div key={input.id} className="form-input">
          <label>{input.label}</label>
          <FormInput
            {...input}
            value={formValues[input.name]}
            onChange={onChange}
          ></FormInput>
        </div>
      ))}
      {/* <div className="form-input">
        <label>Message</label>
        <textarea name="message" id="message" cols="30" rows="10"></textarea>
      </div> */}
      <div>
        <button name="sendMessage" type="submit" id="sendMessage">
          Send
        </button>
        {success ? (
          <div className="message__success">
            <h4>Message sent successfully!</h4>
            <p>
              Thank you for your message, I'll get back to you as soon as
              possible 😊
            </p>
          </div>
        ) : null}
      </div>
    </form>
  );
}

export default ContactForm;
