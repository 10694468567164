import "./NotFound.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function NotFound() {
  return (
    <>
      <Header />
      <main className="main__container">
        <span>NotFound Page</span>
      </main>
      <Footer />
    </>
  );
}

export default NotFound;
