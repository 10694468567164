import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Piiquante.scss";

function Piiquante() {
  return (
    <>
      <Header />
      <main className="main__container">
        <span>Piiquante</span>
      </main>
      <Footer />
    </>
  );
}

export default Piiquante;
