import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./LaPanthere.scss";

function LaPanthere() {
  return (
    <>
      <Header />
      <main className="main__container">
        <span>LaPanthere</span>
      </main>
      <Footer />
    </>
  );
}

export default LaPanthere;
